import axios from 'axios'

export default class Users {
    constructor() {
        this.client = axios
    }

    get(page) {
        return this.client.get('/admin/users?page=' + page)
    }
}