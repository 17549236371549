import {
    SET_TEAMS,
    SET_ROLES,
    SET_USERS,
    SET_COMPANIES,
    SET_ACTIVE_USER,
    SET_USER_PAGINATION,
    SET_CURRENT_USER_PAGE
} from '../mutation-types'

import Users from "../../api/users"

const userClient = new Users()

const state = {
    users: [],
    roles: [],
    teams: [],
    companies: [],
    pagination: {
        current: 1,
        totalPages: [1],
        perPage: 10,
        total: 10,
    },
    activeUser: {}
}

const getters = {
    teams: state => state.teams,
    roles: state => state.roles,
    users: state => state.users,
    companies: state => state.companies,
    activeUser: state => state.activeUser,
    usersPagination: state => state.pagination
}

const actions = {
    async getUsers({commit, state}) {
        let {data} = await userClient.get(state.pagination.current)
        commit(SET_USER_PAGINATION, data)
        commit(SET_USERS, data.users.data)
        commit(SET_ROLES, data.roles)
        commit(SET_TEAMS, data.teams)
        commit(SET_COMPANIES, data.companies)
    },
    setActiveUser({commit}, user) {
        commit(SET_ACTIVE_USER, user)
    },
    setCurrentUserPage({commit, dispatch}, page) {
        commit(SET_CURRENT_USER_PAGE, page)
        dispatch('getUsers')
    }
}

const mutations = {
    [SET_ACTIVE_USER](state, user) {
        state.activeUser = user
    },
    [SET_USERS](state, users) {
        state.users = users
    },
    [SET_ROLES](state, roles) {
        state.roles = roles
    },
    [SET_TEAMS](state, teams) {
        state.teams = teams
    },
    [SET_COMPANIES](state, companies) {
        state.companies = companies
    },
    [SET_USER_PAGINATION](state, data) {
        state.pagination.current = data.users.current_page
        state.pagination.totalPages = Array(data.users.last_page).fill().map((_, i) => i + 1)
        state.pagination.total = data.users.total
        state.pagination.perPage = data.users.per_page
    },
    [SET_CURRENT_USER_PAGE](state, page) {
        state.pagination.current = page
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
