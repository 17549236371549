import {
    SET_CONVERSATIONS,
    SET_ACTIVE_MESSAGE,
    SET_ACTIVE_CONVERSATION,
    SET_CONVERSATIONS_LOADING,
    SET_CONVERSATION_PAGINATION,
    SET_CURRENT_CONVERSATION_PAGE
} from '../mutation-types'

import Conversations from '../../api/conversations'

const conversationClient = new Conversations()

const namespaced = true

const state = {
    conversations: [],
    pagination: {
        current: 1,
        totalPages: [1],
        perPage: 10,
        total: 10,
    },
    recipientPagination: {
        current: 1,
        totalPages: [1],
        perPage: 10,
        total: 10,
    },
    conversation: {
        name: null
    },
    loadingConversations: false,
    message: ''
}

const getters = {
    loadingConversations: state => state.loadingConversations,
    activeConversation: state => state.conversation,
    activeMessage: state => state.message,
    conversations: state => state.conversations,
    conversationPagination: state => state.pagination
}

const actions = {
    async getConversations ({ commit, state }, recipientId) {
        commit(SET_CONVERSATIONS_LOADING, true)
        commit(SET_CONVERSATIONS, [])

        conversationClient.setPage(state.pagination.current)
        let { data } = await conversationClient.get(recipientId)
        commit(SET_CONVERSATION_PAGINATION, data)
        commit(SET_CONVERSATIONS, data.conversations.data)
        commit(SET_CONVERSATIONS_LOADING, false)
    },
    setActiveConversation ({ commit }, conversation) {
        commit(SET_ACTIVE_CONVERSATION, conversation)
    },
    setActiveMessage ({ commit }, message) {
        commit(SET_ACTIVE_MESSAGE, message)
    },
    setCurrentConversationPage ({ commit, dispatch }, page) {
        commit(SET_CURRENT_CONVERSATION_PAGE, page)
        dispatch('getConversations')
    },
    async readConversation ({ commit, state }, recipientId) {
        await conversationClient.read(recipientId)
    }
}

const mutations = {
    [SET_ACTIVE_CONVERSATION] (state, conversation) {
        state.conversation = conversation
    },
    [SET_ACTIVE_MESSAGE] (state, message) {
        state.message = message
    },
    [SET_CONVERSATIONS] (state, conversations) {
        state.conversations = conversations
    },
    [SET_CONVERSATION_PAGINATION] (state, data) {
        state.pagination.current = data.conversations.current_page
        state.pagination.totalPages = Array(data.conversations.last_page).fill().map((_, i) => i + 1)
        state.pagination.total = data.conversations.total
        state.pagination.perPage = data.conversations.per_page
    },
    [SET_CURRENT_CONVERSATION_PAGE] (state, page) {
        state.pagination.current = page
    },
    [SET_CONVERSATIONS_LOADING] (state, loading) {
        state.loadingConversations = loading
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced,
}
