import {
    SHOW_MODAL,
    HIDE_MODAL,
} from '../mutation-types'

const state = {
    modal: {
        visible: false
    }
}

const getters = {
    isModalVisible: state => state.modal.visible,
}

const actions = {
    showModal({commit}) {
        commit(SHOW_MODAL)
    },
    hideModal({commit}) {
        commit(HIDE_MODAL)
    },
}

const mutations = {
    [SHOW_MODAL](state) {
        state.modal.visible = true
    },
    [HIDE_MODAL](state) {
        state.modal.visible = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
