import axios from 'axios'

export default class Conversations {
    constructor(page) {
        this.page = page
        this.client = axios
    }

    get(recipientId) {
        return this.client.get('/conversations/?page=' + this.page + '&recipient_id=' + recipientId)
    }

    read(conversationId) {
        return this.client.post('/read-messages', {
            id: conversationId
        })
    }

    setPage(page) {
        this.page = page
    }

    getByTags(tags) {
        return this.client.post('/conversations?page=' + this.page)
    }

    getByQuery(query) {
        return this.client.post('/conversations?page=' + this.page)
    }

    getRecipients() {
        return this.client.get('/conversation-recipients?page=' + this.page)
    }
}