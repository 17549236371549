import {
    ADD_RECIPIENTS,
    GET_RECIPIENTS,
    UPDATE_RECIPIENT,
    DELETE_RECIPIENT,
    SET_CURRENT_PAGE,
    SET_RECIPIENT_PAGINATION
} from "../mutation-types"

import RecipientClient from "../../api/recipient-client"

const recipientClient = new RecipientClient()

const namespaced = true

const state = {
    recipients: [{
        id: null,
        name: '',
        email: '',
        phone: '',
        created_at: '',
        updated_at: ''
    }],
    pagination: {
        current: 1,
        totalPages: [1],
        perPage: 10,
        total: 10,
    },
}

const getters = {
    recipients: (state, getters, rootState, rootGetters) => {
        // return state.recipients.filter(recipient => recipient.tag === rootGetters.currentTag.name.en)
        return state.recipients
    },
    recipientPagination: state => state.pagination,
    currentTag: (state, getters, rootState, rootGetters) => {
        return rootGetters.currentTag.name.en
    }
}

const actions = {
    async getRecipients({commit, state}, tag) {
        recipientClient.setCurrentPage(state.pagination.current)
        let {data} = await recipientClient.get(tag)
        commit(GET_RECIPIENTS, data.video_recipients.data)
        commit(SET_RECIPIENT_PAGINATION, data)
    },
    async addRecipients({commit}, recipients) {
        let {data} = await recipientClient.create(recipients)
        commit(ADD_RECIPIENTS, data.video_recipients)
    },
    async updateRecipient({commit}, recipient) {
        let {data} = await recipientClient.edit(recipient)
        commit(UPDATE_RECIPIENT, data.video_recipient)
    },
    async deleteRecipient({commit}, recipient) {
        await recipientClient.delete(recipient)
        commit(DELETE_RECIPIENT, recipient)
    },
    setCurrentPage({commit, dispatch, getters}, currentPage) {
        commit(SET_CURRENT_PAGE, currentPage)
        dispatch('getRecipients', getters.currentTag)
    }
}

const mutations = {
    [GET_RECIPIENTS](state, recipients) {
        state.recipients = recipients
    },
    [ADD_RECIPIENTS](state, recipients) {
        state.recipients = recipients
    },
    [UPDATE_RECIPIENT](state, recipient) {
        state.recipients = state.recipients.map((el) => {
            if (el.id === recipient.id) {
                el = recipient
            }

            return el
        })
    },
    [DELETE_RECIPIENT](state, recipient) {
        let index = state.recipients.findIndex((el) => el.id === recipient.id)
        state.recipients.splice(index, 1)
    },
    [SET_RECIPIENT_PAGINATION](state, data) {
        state.pagination.current = data.video_recipients.current_page
        state.pagination.totalPages = Array(data.video_recipients.last_page).fill().map((_, i) => i + 1)
        state.pagination.total = data.video_recipients.total
        state.pagination.perPage = data.video_recipients.per_page
    },
    [SET_CURRENT_PAGE](state, page) {
        state.pagination.current = page
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced
}
