import Velocity from "velocity-animate"

export function openSidebar() {
    let sidebar = document.getElementById('sidebar')

    if (window.innerWidth <= 991) {
        let nav = sidebar.querySelector('.navbar-nav')
        document.getElementById('overlay').style.display = 'block'
        Velocity(nav, {
            left: 0
        }, 500)
        return
    }

    let logo = document.getElementById('desktop-logo')
    let mobileLogo = document.getElementById('mobile-logo')
    mobileLogo.style.display = 'none'
    logo.style.display = 'block'

    sidebar.classList.remove('col-xl-1')
    sidebar.classList.add('col-xl-2')
}

export function closeSidebar() {
    let sidebar = document.getElementById('sidebar')
    if (window.innerWidth <= 991) {
        let nav = sidebar.querySelector('.navbar-nav')
        document.getElementById('overlay').style.display = 'none' +
            ''
        Velocity(nav, {
            left: '-100%'
        }, 500)
        return
    }

    let logo = document.getElementById('desktop-logo')
    let mobileLogo = document.getElementById('mobile-logo')
    setTimeout(() => {
        logo.style.display = 'none'
        mobileLogo.style.display = 'block'
    }, 500)

    sidebar.classList.remove('col-xl-2')
    sidebar.classList.add('col-xl-1')
}

export function openRightSidebar() {
    let rightSidebar = document.getElementById('right-sidebar')

    if (window.innerWidth <= 991) {
        rightSidebar.style.display = 'block'
        Velocity(rightSidebar, {
            'top': 0,
            'bottom': '100%'
        }, 500)
        return
    }
    Velocity(rightSidebar, {
        'right': 0
    }, 500)
}

export function closeRightSidebar() {
    let rightSidebar = document.getElementById('right-sidebar')

    if (window.innerWidth <= 991) {
        Velocity(rightSidebar, {
            'top': '100%',
            'bottom': '-100%'
        }, 500).then(() => {
            rightSidebar.style.display = 'none'
        })
        return
    }

    Velocity(rightSidebar, {
        'right': '100%'
    }, 500)
}

export function openMidSidebar() {
    let sidebar = document.getElementById('mid-sidebar')
    sidebar.style.display = 'block'

    if (window.innerWidth <= 991) {
        Velocity(sidebar, {
            right: 0
        }, 500)
    }
    // sidebar.classList.remove('col-xl-1')
    // sidebar.classList.add('col-xl-2')
}

export function closeMidSidebar() {
    let sidebar = document.getElementById('mid-sidebar')
    if (window.innerWidth <= 991) {
        Velocity(sidebar, {
            right: '-100%'
        }, 500).then(() => {
            sidebar.style.display = 'none'
        })
    }
    // sidebar.classList.remove('col-xl-2')
    // sidebar.classList.add('col-xl-1')
}