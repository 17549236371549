export const PLAY_VIDEO = 'PLAY_VIDEO'
export const STOP_VIDEO = 'STOP_VIDEO'
export const SHOW_VIDEO = 'SHOW_VIDEO'
export const HIDE_VIDEO = 'HIDE_VIDEO'
export const SET_VIDEO_SOURCE = 'SET_VIDEO_SOURCE'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const SHOW_OVERLAY = 'SHOW_OVERLAY'
export const HIDE_OVERLAY = 'HIDE_OVERLAY'

export const ADD_TAG = 'ADD_TAG'
export const GET_TAGS = 'GET_TAGS'
export const UPDATE_TAG = 'UPDATE_TAG'
export const DELETE_TAG = 'DELETE_TAG'
export const SAVING_TAG = 'SAVING_TAG'
export const SET_PER_PAGE = 'SET_PER_PAGE'
export const SET_CURRENT_TAG = 'SET_CURRENT_TAG'
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export const ADD_RECIPIENTS = 'ADD_RECIPIENTS'
export const DELETE_RECIPIENT = 'DELETE_RECIPIENT'
export const GET_RECIPIENTS = 'GET_RECIPIENTS'
export const UPDATE_RECIPIENT = 'UPDATE_RECIPIENT'
export const SET_RECIPIENT_PAGINATION = 'SET_RECIPIENT_PAGINATION'

export const SET_ROLES = 'SET_ROLES'
export const SET_TEAMS = 'SET_TEAMS'
export const SET_USERS = 'SET_USERS'
export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER'
export const SET_USER_PAGINATION = 'SET_USER_PAGINATION'
export const SET_CURRENT_USER_PAGE = 'SET_CURRENT_USER_PAGE'

export const SET_CURRENT_FORM = 'SET_CURRENT_FORM'
export const SET_CURRENT_TABLE = 'SET_CURRENT_TABLE'

export const SET_CONVERSATIONS = 'SET_CONVERSATIONS'
export const SET_ACTIVE_MESSAGE = 'SET_ACTIVE_MESSAGE'
export const SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION'
export const SET_CONVERSATIONS_LOADING = 'SET_CONVERSATIONS_LOADING'
export const SET_CONVERSATION_PAGINATION = 'SET_CONVERSATION_PAGINATION'
export const SET_CURRENT_CONVERSATION_PAGE = 'SET_CURRENT_CONVERSATION_PAGE'

export const SET_TAG_SEARCH = 'SET_TAG_SEARCH'
export const SET_TEXT_SEARCH = 'SET_TEXT_SEARCH'
export const SET_UNREAD_SEARCH = 'SET_UNREAD_SEARCH'
export const SET_RECIPIENT_CONVERSATIONS = 'SET_RECIPIENT_CONVERSATIONS'
export const SET_ACTIVE_RECIPIENT_CONVERSATION = 'SET_ACTIVE_RECIPIENT_CONVERSATION'
export const SET_RECIPIENT_CONVERSATION_PAGINATION = 'SET_RECIPIENT_CONVERSATION_PAGINATION'
export const SET_RECIPIENT_CURRENT_CONVERSATION_PAGE = 'SET_RECIPIENT_CURRENT_CONVERSATION_PAGE'

export const SET_ACTIVE_VIDEO = 'SET_ACTIVE_VIDEO'

