import {
    SET_CURRENT_FORM,
    SET_CURRENT_TABLE,
} from '../mutation-types'

const state = {
    mobile: window.innerWidth <= 991,
    currentForm: null,
    currentTable: 'users-table',
}

const getters = {
    isMobile: state => state.mobile,
    currentForm: state => state.currentForm,
    currentTable: state => state.currentTable
}

const actions = {
    setCurrentForm({commit}, name) {
        commit(SET_CURRENT_FORM, name)
    },
    setCurrentTable({commit, dispatch}, name) {
        dispatch('setCurrentForm')
        commit(SET_CURRENT_TABLE, name)
    },
}

const mutations = {
    [SET_CURRENT_FORM](state, name) {
        state.currentForm = name
    },
    [SET_CURRENT_TABLE](state, name) {
        state.currentTable = name
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}

