import axios from 'axios'

export default class TagClient {
    constructor() {
        this.client = axios
        this.currentPage = 1
    }

    get(tag) {
        let url = encodeURI(tag)
        return this.client.get(`/video-recipients?tag=${url}&page=${this.currentPage}`)
    }

    create(recipient) {
        return this.client.post('/video-recipients', recipient)
    }

    edit(recipient) {
        return this.client.put(`/video-recipients/${recipient.id}`, recipient)
    }

    delete(recipient) {
        return this.client.delete(`/video-recipients/${recipient.id}`)
    }

    setCurrentPage(page) {
        this.currentPage = page
    }
}
