import {
    SET_ACTIVE_VIDEO,
} from '../mutation-types'

const state = {
    activeVideo: {}
}

const getters = {
    activeVideo: state => state.activeVideo,
}

const actions = {
    setActiveVideo({commit}, video) {
        commit(SET_ACTIVE_VIDEO, video)
    },
}

const mutations = {
    [SET_ACTIVE_VIDEO](state, video) {
        state.activeVideo = video
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
