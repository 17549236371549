import Vue from 'vue'
import Vuex from 'vuex'
import Tag from './modules/tags.js'
import Users from './modules/users.js'
import Modal from './modules/modal.js'
import Videos from './modules/videos.js'
import Options from './modules/options.js'
import Overlay from './modules/overlay.js'
import Video from './modules/video-player.js'
import Recipient from './modules/recipients.js'
import Conversations from './modules/conversations.js'
import RecipientConversations from './modules/recipient-conversations.js'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        Tag,
        Users,
        Modal,
        Video,
        Videos,
        Overlay,
        Options,
        RecipientConversations,
        recipients: Recipient,
        conversations: Conversations,
    },
})
