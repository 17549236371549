import {
    ADD_TAG,
    GET_TAGS,
    UPDATE_TAG,
    DELETE_TAG,
    SAVING_TAG,
    SET_CURRENT_TAG
} from '../mutation-types'

import TagClient from '../../api/tag-client'

const tagClient = new TagClient()

const state = {
    tags: [{
        id: null,
        name: {
            en: ''
        },
        slug: {
            en: ''
        },
        type: null,
        order_column: 0,
        created_at: '',
        updated_at: ''
    }],
    currentTag: {
        name: null
    },
    savingTag: false
}

const getters = {
    savingTag: state => state.savingTag,
    currentTag: state => state.currentTag,
    tags: state => state.tags,
}

const actions = {
    async getTags ({ commit }) {
        let { data } = await tagClient.get()
        commit(GET_TAGS, data.tags)
    },
    async addTag ({ commit }, name) {
        let { data } = await tagClient.create(name)
        commit(ADD_TAG, data.tag)
    },
    async updateTag ({ commit }, tag) {
        let { data } = await tagClient.edit(tag)
        if (data.is_new) {
            window.location.href = '/video-recipient/' + data.tag.id
        } else {
            commit(UPDATE_TAG, data.tag)
        }
    },
    async deleteTag ({ commit }, tag) {
        await tagClient.delete(tag)
        commit(DELETE_TAG, tag)
    },
    setCurrentTag ({ commit, dispatch, state }, index) {
        commit(SET_CURRENT_TAG, index)
        dispatch('recipients/getRecipients', state.currentTag.name.en, { root: true })
    },
    setSavingTag ({ commit }, saving) {
        commit(SAVING_TAG, saving)
    }
}

const mutations = {
    [ADD_TAG] (state, tag) {
        state.tags.push(tag)
    },
    [GET_TAGS] (state, tags) {
        state.tags = tags
    },
    [UPDATE_TAG] (state, tag) {
        state.tags = state.tags.map((el) => {
            if (el.id === tag.id) {
                el = tag
            }

            return el
        })
    },
    [SET_CURRENT_TAG] (state, index) {
        state.currentTag = state.tags[index]
    },
    [DELETE_TAG] (state, tag) {
        let index = state.tags.findIndex((el) => el.id === tag.id)
        state.tags.splice(index, 1)
    },
    [SAVING_TAG] (state, saving) {
        state.savingTag = saving
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
