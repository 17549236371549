import Vue2TouchEvents from "vue2-touch-events";

require('./bootstrap.js')

import './../../resources/sass/app.scss'

import Vue from 'vue'
import 'core-js/stable'
import store from './store/index.js'
import VTooltip  from "v-tooltip"
import 'regenerator-runtime/runtime'
import * as Sentry from '@sentry/browser'
import {mapGetters, mapActions} from 'vuex'
import Burger from './components/utilities/Burger'
import * as Integrations from '@sentry/integrations'
import {sweetAlert} from "./utilities/sweet-alert.js"

import {
    openSidebar,
    closeSidebar,
    closeRightSidebar,
    closeMidSidebar
} from './utilities/sidebar-menus'

Sentry.init({
    dsn: 'https://d289b96ce9fb47caac39fde3f9c92905@sentry.io/1844032',
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
})

Vue.use(VTooltip)
Vue.use(Vue2TouchEvents)

import('vue-multiselect').then(({Multiselect}) => {
    Vue.component('multiselect', Multiselect)
})

Vue.component('pi-modal', () => import('./components/utilities/Modal.vue'))
Vue.component('stop-button', () => import('./components/utilities/StopButton.vue'))
Vue.component('loading-overlay', () => import('./components/utilities/LoadingOverlay.vue'))
Vue.component('currency-input', () => import('./components/utilities/CurrencyInput.vue'))
Vue.component('image-slider', () => import('./components/utilities/ImageSlider.vue'))

const app = new Vue({
    el: '#app',
    data: {
        isMenuOpened: true,
        sweetAlert: null
    },
    store,
    computed: {
        ...mapGetters({
            isMobile: 'isMobile',
            activeUser: 'activeUser',
            currentTag: 'currentTag',
            currentForm: 'currentForm',
            currentTable: 'currentTable',
            isOverlayVisible: 'isOverlayVisible',
            recipients: 'recipients/recipients',
            pagination: 'recipients/recipientPagination',
        })
    },
    mounted() {
        if (this.isMobile) {
            this.isMenuOpened = false
        }

        // const params = new URLSearchParams(window.location.search)
        // const tag = params.get('tag')
        //
        // if (tag) {
        //     console.log(this.tags)
        //     let index = this.tags.findIndex((tag) => {
        //         console.log(tag.slug.en)
        //         return tag.slug.en === tag
        //     })
        //     this.setCurrentTagService(index)
        // }
    },
    methods: {
        closeMenu() {
            this.isMenuOpened = false
            closeSidebar()
        },
        openMenu() {
            openSidebar()
            setTimeout(() => {
                this.isMenuOpened = true
            }, 600)
        },
        toggleMenu() {
            if (this.isMenuOpened) {
                this.closeMenu()
            } else {
                this.openMenu()
            }
        },
        closeRightSidebar() {
            closeRightSidebar()
            this.setActiveUser({})
        },

        closeMidSidebar() {
            closeMidSidebar()
            this.setActiveRecipientConversation({})
        },
        onDelete(recipient) {
            sweetAlert.fire({
                title: 'Are you sure?',
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.deleteRecipient(recipient).then(() => {
                        sweetAlert.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    })
                } else {
                    sweetAlert.fire(
                        'Cancelled',
                        recipient.name + ' was not deleted',
                        'success'
                    )
                }
            })
        },
        setCurrentPage(page) {
            this.setCurrentPageService(parseInt(page))
        },
        ...mapActions({
            Burger,
            setActiveUser: 'setActiveUser',
            setCurrentForm: 'setCurrentForm',
            setCurrentTable: 'setCurrentTable',
            deleteRecipient: 'recipients/deleteRecipient',
            setCurrentPageService: 'recipients/setCurrentPage',
            setCurrentTagService: 'setCurrentTag',
            setActiveRecipientConversation: 'setActiveRecipientConversation',
        })
    },
    components: {
        'burger': () => import('./components/utilities/Burger.vue'),
        'video-player': () => import('./components/videos/VideoPlayer.vue'),
        'desktop-video-player': () => import('./components/videos/DesktopVideoPlayer.vue'),
        'video-recorder': () => import('./components/videos/VideoRecorder.vue'),
        'demo-video-recorder': () => import('./components/videos/DemoVideoRecorder.vue'),
        'users-table': () => import('./components/tables/UsersTable.vue'),
        'recipient-conversations': () => import('./components/conversations/RecipientConversations.vue'),
        'conversations': () => import('./components/conversations/AllConversations.vue'),
        'invitations-table': () => import('./components/tables/InvitationsTable.vue'),
        'teams-table': () => import('./components/tables/TeamsTable.vue'),
        'folding-cube': () => import('./components/utilities/loaders/FoldingCube.vue'),
        'video-recipients': () => import('./components/video-recipients/MassUploadVideoForm.vue'),
        'create-recipient-tags': () => import('./components/video-recipients/CreateVideoRecipientTags.vue'),
        'message-settings': () => import('./components/forms/MessageSettingsForm.vue'),
        'personal-settings': () => import('./components/forms/PersonalSettingsForm.vue'),
        'company-settings': () => import('./components/forms/CompanySettingsForm.vue'),
        'edit-video': () => import('./components/forms/EditVideoForm.vue'),
        'edit-user': () => import('./components/forms/EditUserForm.vue'),
        'create-user': () => import('./components/forms/CreateUserForm.vue'),
        'typed-message': () => import('./components/messages/TypedMessage.vue'),
        'video-message-prompt': () => import('./components/forms/VideoOrMessagePrompt.vue'),
        'video-message-view': () => import('./components/messages/ViewMessage.vue'),
        'user-profile': () => import('./components/profiles/UserProfile.vue'),
        'desktop-user-profile': () => import('./components/profiles/DesktopUserProfile.vue'),
        'pagination': (() => import('./components/utilities/Pagination'))
    },
})
