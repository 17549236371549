import axios from 'axios'

export default class RecipientConversations {
    constructor(page) {
        this.page = page
        this.client = axios
    }

    get() {
        return this.client.get('/conversation-recipients?page=' + this.page)
    }

    getByQuery(query) {
        return this.client.get('/conversation-recipients?page=' + this.page + '&query=' + query)
    }

    getUnread() {
        return this.client.get('/conversation-recipients?page=' + this.page + '&unread=true')
    }

}