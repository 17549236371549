import {
    SHOW_OVERLAY,
    HIDE_OVERLAY,
} from '../mutation-types'

const state = {
    overlay: {
        visible: false
    }
}

const getters = {
    isOverlayVisible: state => state.overlay.visible,
}

const actions = {
    showOverlay({commit}) {
        commit(SHOW_OVERLAY)
    },
    hideOverlay({commit}) {
        commit(HIDE_OVERLAY)
    },
}

const mutations = {
    [SHOW_OVERLAY](state) {
        state.overlay.visible = true
    },
    [HIDE_OVERLAY](state) {
        state.overlay.visible = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
