import {
    PLAY_VIDEO,
    STOP_VIDEO,
    SHOW_VIDEO,
    HIDE_VIDEO,
    SET_VIDEO_SOURCE
} from '../mutation-types'

const state = {
    video: {
        visible: false,
        playing: false,
        src: ''
    },
}

const getters = {
    isVideoPlaying: state => state.video.playing,
    isVideoVisible: state => state.video.visible,
    getVideoSource: state => state.video.src,
}

const actions = {
    playVideo({commit}) {
        commit(PLAY_VIDEO)
    },
    stopVideo({commit, dispatch}) {
        commit(STOP_VIDEO)
        dispatch('hideVideo')
    },
    showVideo({commit}) {
        commit(SHOW_VIDEO)
    },
    hideVideo({commit}) {
        commit(HIDE_VIDEO)
    },
    updateVideoSource({commit}, src) {
        commit(SET_VIDEO_SOURCE, src)
    },
}

const mutations = {
    [PLAY_VIDEO](state) {
        state.video.playing = true
    },
    [STOP_VIDEO](state) {
        state.video.playing = false
    },
    [SHOW_VIDEO](state) {
        state.video.visible = true
    },
    [HIDE_VIDEO](state) {
        state.video.visible = false
    },
    [SET_VIDEO_SOURCE](state, src) {
        state.video.src = src
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
