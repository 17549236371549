import axios from 'axios'

export default class TagClient {
    constructor() {
        this.client = axios
    }

    get() {
        return this.client.get(`/tags`)
    }

    create(name) {
        return this.client.post('/tags', {
            name: name
        })
    }

    edit(tag) {
        return this.client.put(`/tags/${tag.id}`, tag)
    }

    delete(tag) {
        //remove tag from client
        return this.client.delete(`/tags/${tag.id}`)
    }
}